import * as React from "react"
import { graphql } from "gatsby"
import VideoHero from "../components/VideoHero"
import ConnectSection from "../components/connectSection"

import Apparel from "../images/services/apparel-wht.svg"
import Accessories from "../images/services/accessories-wht.svg"
import Collectibles from "../images/services/collectibles-wht.svg"
import Packaging from "../images/services/packaging-wht.svg"
import Experiential from "../images/services/experiential-wht.svg"
import Gear from "../images/services/gear-wht.svg"
import Garments from "../images/services/garments-wht.svg"
import Homegoods from "../images/services/homegoods-wht.svg"


import client1 from "../images/clients/client-1.png"
import clientH1 from "../images/clients/client-h1.png"
import client2 from "../images/clients/client-2.png"
import clientH2 from "../images/clients/client-h2.png"
import client3 from "../images/clients/client-3.png"
import clientH3 from "../images/clients/client-h3.png"
import client4 from "../images/clients/client-4.png"
import clientH4 from "../images/clients/client-h4.png"
import client5 from "../images/clients/client-5.png"
import clientH5 from "../images/clients/client-h5.png"
import client6 from "../images/clients/client-6.png"
import clientH6 from "../images/clients/client-h6.png"
import client7 from "../images/clients/client-7.png"
import clientH7 from "../images/clients/client-h7.png"
import client8 from "../images/clients/client-8.png"
import clientH8 from "../images/clients/client-h8.png"
import client9 from "../images/clients/client-9.png"
import clientH9 from "../images/clients/client-h9.png"
import client10 from "../images/clients/client-10.png"
import clientH10 from "../images/clients/client-h10.png"
import client11 from "../images/clients/client-11.png"
import clientH11 from "../images/clients/client-h11.png"
import client12 from "../images/clients/client-12.png"
import clientH12 from "../images/clients/client-h12.png"
import client13 from "../images/clients/client-13.png"
import clientH13 from "../images/clients/client-h13.png"
import client14 from "../images/clients/client-14.png"
import clientH14 from "../images/clients/client-h14.png"
import client15 from "../images/clients/client-15.png"
import clientH15 from "../images/clients/client-h15.png"
import client16 from "../images/clients/client-16.png"
import clientH16 from "../images/clients/client-h16.png"
import client17 from "../images/clients/client-17.png"
import clientH17 from "../images/clients/client-h17.png"
import client18 from "../images/clients/client-18.png"
import clientH18 from "../images/clients/client-h18.png"
import client19 from "../images/clients/client-19.png"
import clientH19 from "../images/clients/client-h19.png"
import client20 from "../images/clients/client-20.png"
import clientH20 from "../images/clients/client-h20.png"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const clients = [
    { id: 1, src: clientH1, hoverSrc: client1 },
    { id: 2, src: clientH2, hoverSrc: client2 },
    { id: 3, src: clientH3, hoverSrc: client3 },
    { id: 4, src: clientH4, hoverSrc: client4 },
    { id: 5, src: clientH5, hoverSrc: client5 },
    { id: 6, src: clientH6, hoverSrc: client6 },
    { id: 7, src: clientH7, hoverSrc: client7 },
    { id: 8, src: clientH8, hoverSrc: client8 },
    { id: 9, src: clientH9, hoverSrc: client9 },
    { id: 10, src: clientH10, hoverSrc: client10 },
    { id: 11, src: clientH11, hoverSrc: client11 },
    { id: 12, src: clientH12, hoverSrc: client12 },
    { id: 12, src: clientH13, hoverSrc: client13 },
    { id: 12, src: clientH14, hoverSrc: client14 },
    { id: 12, src: clientH15, hoverSrc: client15 },
    { id: 12, src: clientH16, hoverSrc: client16 },
    { id: 12, src: clientH17, hoverSrc: client17 },
    { id: 12, src: clientH18, hoverSrc: client18 },
    { id: 12, src: clientH19, hoverSrc: client19 },
    { id: 12, src: clientH20, hoverSrc: client20 },
  ]

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container text-base overflow-hidden max-w-6xl">

        <div className="">
          <h2 className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mb-5">Think. Make. Sell.</h2>
          <ul role="list" className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-3 mt-5">
            <li className="relative overflow-hidden">
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                <h3 className="text-2xl font-bold mb-3">Think</h3>
                <p className="text-base">It all starts with an idea. Our team of creative directors, graphic designers, product designers and illustrators work together with our partners to bring it to life.</p>
              </div>
            </li>
            <li className="relative overflow-hidden">
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                <h3 className="text-2xl font-bold mb-3">Make</h3>
                <p className="text-base">We design, manufacture, and package an expansive range of apparel, headwear, accessories, and collectibles like vinyl toys, plushies, trading cards, comics and more.</p>
              </div>
            </li>
            <li className="relative overflow-hidden">
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                <h3 className="text-2xl font-bold mb-3">Sell</h3>
                <p className="text-base">Our Web team builds customized websites and e-commerce shops for our partners. Your online destination needs to be unique and distinct, while staying simple and easy-to-navigate.</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="">
          <h2 className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mt-10 mb-5">How it all comes together</h2>
          <p className="my-5">At Flatblack, The Creative, Production and Fulfillment teams work together as a unit, making the process seamless and strategic.</p>
          <p className="my-5">This fosters thoughtful design that can save costs, a manufacturing team that thinks like designers and catches crucial details, and a sales team that knows exactly what to release and when—creating value for the customer and ensuring a memorable, repeatable experience.</p> 
        </div>

        {/* <div className="my-20 drop-shadow-xl">
          <VideoHero />
        </div> */}

        <div className="">
          <h2 className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mt-10 mb-5">Clients + Partners</h2>
          <p className="text-base">We work best with clients who are just as hands-on as we are—engaged, creative, and ready to collaborate. Whether you've got a product idea and need help bringing it to life, or you're focused on a campaign and need the perfect product to match, we're here to make it happen. Our current partners, from gaming and music to content creation and lifestyle brands, trust us to develop, distribute, and optimize products that fit their vision.</p>

          <ul role="list" className="grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-5 mt-8">
            {clients.map(client => (
              <li key={client.id} className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  
                  <img src={client.hoverSrc} alt="" className="pointer-events-none object-cover  absolute left-1/2 transform -translate-x-1/2 -translate-y-full transition-transform duration-700 ease-in-out group-hover:translate-y-1" />
                  <img src={client.src} alt="" className="pointer-events-none object-cover hover:absolute  hover:transform translate-y-1 duration-700 ease-in-out group-hover:translate-y-32" />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="">
          <h2 className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mt-10 mb-5">Services & Capabilities</h2>
          <ul role="list" className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-3 mt-5">
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-xl font-bold mb-3 uppercase">DESIGN + CREATIVE</h3>
                  <p className="text-base">Our team can turn any creative vision into reality, whether it's stunning graphics, rebranding company identities, unique illustrations, standout logos, innovative products, or unforgettable experiences</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-xl font-bold mb-3 uppercase">PRODUCTION</h3>
                  <p className="text-base">From sourcing and sampling to full-scale production, we've got every step covered to bring your ideas to life with top-notch quality. Every product is sampled first, details are dialed before we make them. We specialize in apparel, accessories, wearable gear and collectibles.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-xl font-bold mb-3 uppercase">CONTENT CREATION</h3>
                  <p className="text-base">Our in-house photo and video team creates high-quality content for product shoots, lookbooks, and campaign videos. We also offer these services to external clients, providing professional visuals to enhance your brand and elevate your product launches.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-xl font-bold mb-3 uppercase">Web Design</h3>
                  <p className="text-base">We're here to help you build an online presence that truly works for your business. Whether you need a website that stands out or an e-commerce setup that makes shopping simple, we'll use the right technology to bring your vision to life and help you grow in the digital space.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-xl font-bold mb-3 uppercase">XP / EVENTS</h3>
                  <p className="text-base">We specialize in crafting unforgettable experiences with immersive activations, custom booth designs, and live events that drive engagement and leave a lasting impression. Our team manages the entire process, from concept, fabrication, setup and breakdown.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-xl font-bold mb-3 uppercase">FULFILLMENT + LOGISTICS</h3>
                  <p className="text-base">We've got all your logistics covered by providing secure warehousing, flexible shipping options, and in-house customer service. This includes drop shipping to individual customers and bulk shipping to retailers, we handle everything to ensure smooth transit from launch to delivery.</p>
                </div>
              </li>
            </ul>
        </div>

        <div className="my-20 drop-shadow-xl">
          <ConnectSection />
        </div>

        <div className="md:my-20">
            <h2 className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mt-10 mb-5">Things we make.</h2>
            <ul role="list" className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4 mt-8">
              <li className="relative overflow-hidden md:mb-5">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Apparel className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Apparel</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Shirts</li>
                      <li>Fleece</li>
                      <li>Outerwear</li>
                      <li>Sweatpants</li>
                      <li>Shorts</li>
                      <li>Headwear</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden md:mb-5">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Accessories className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Headwear + Accessories</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Headwear</li>
                      <li>Eyewear</li>
                      <li>Backpacks / Bags</li>
                      <li>Jewelry</li>
                      <li>Enamel Pins</li>
                      <li>Socks / Scarves</li>
                      <li>Wallets / Belts</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden md:mb-5">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Collectibles className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Collectibles</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Plush Toys / Plushies</li>
                      <li>Vinyl Toys / Figurines</li>
                      <li>Retro / Novelty items</li>
                      <li>Trading cards</li>
                      <li>Comic books</li>
                      <li>Stickers / Posters / Prints</li>
                      <li>Skatedecks</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden md:mb-5">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Packaging className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Packaging</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Custom boxes</li>
                      <li>Blister Packaging</li>
                      <li>Luxury box</li>
                      <li>Interactive packaging</li>
                      <li>Custom polybags</li>
                      <li>Custom Stickers / Labeling</li>
                      <li>Woven labels / Hangtags</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Experiential className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Experiential</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Live Activations</li>
                      <li>Booth Design / Fabrication</li>
                      <li>Staging / Lighting</li>
                      <li>Audio Visual Production</li>
                      <li>Install & On-site Management</li>
                      <li>Transit / Logistics / Storage</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Gear className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Gear</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Protective wear / Pads</li>
                      <li>Luggage</li>
                      <li>Technical Wear</li>
                      <li>3D Mold</li>
                      <li>CNC Milling</li>
                      <li>Utility Belts / Harnesses / Rigs</li>
                      <li>Gloves</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Garments className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Custom Branded Products</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Cut & Sew Garments</li>
                      <li>Costumes / Mascots</li>
                      <li>Custom speakers</li>
                      <li>Custom headphones</li>
                      <li>Surfboards / Skateboards</li>
                      <li>Custom Audio products</li>
                      <li>Uniforms / Spirit-wear</li>
                    </ul>
                  </p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <Homegoods className="w-20 md:w-24" />
                  <h3 className="text-2xl font-bold my-3">Homegoods</h3>
                  <p className="text-base">
                    <ul className ="list-disc pl-5">
                      <li>Custom candles</li>
                      <li>Neon desk lights</li>
                      <li>Neon mounted lights</li>
                      <li>Rugs</li>
                      <li>Drinkware / Coasters</li>
                      <li>Posters</li>
                    </ul>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
